import styled from 'styled-components';


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${ ( { alignItems = 'center' } ) => alignItems };
    width: ${ ( { number = 1 } ) => ( !!number ? `calc(${ 100 / number }%)` : 'calc(20%)' ) };
    padding: 0 ${({ theme}) => theme.spacing06};
    box-sizing: border-box;
   
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        width: 100%;
    }
    
    /** Added just for printing ebooks **/
    @media print {
        &:nth-child(6n) {
            page-break-after: always;
        }
    }
`;

export default Col;
