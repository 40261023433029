import styled from 'styled-components';

export const ContestPrizeAvatarWrapper = styled.a`
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translate(-50%);

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        transform: translate(-50%, 0) scale(0.8) ;
    }
`;

export default ContestPrizeAvatarWrapper;
