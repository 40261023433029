import styled from 'styled-components';


const FeatureBoxImage = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 72px;
    width: 72px;
    border-radius: 100%;
    text-align: center;
    border: 6px solid  ${ ( { theme } ) => theme.ui02 };
    box-sizing: border-box;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        border-radius: 100%;
    }
`;

export default FeatureBoxImage;
