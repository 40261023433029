import styled from 'styled-components';

export const ContestStepBannerWrapper = styled.img`
    max-width: 70%;

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        max-width: 100%;
    }
`;

export default ContestStepBannerWrapper;
