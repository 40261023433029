export const testimonialsList = [
    "Ta paczka naprawdę miło mnie zaskoczyła. E booki są świetnie napisane ! Są aktualne odnośniki na strony internetowe, wycieczki z dziecmi, trasy rozne. Daliście mi tak wiele pomysłów na te wakacje, że nie wiemy z mężem na co się zdecydować 🫣 najlepiej wydane 200 złotych. Dzięki!!😉",
    "Bardzo przydatna rzecz, szczególnie, że chce zacząć podróżować z małym dzieckiem ",
    "Świetny pomysł!! Dało mi to mega kopa do tego, aby zacząć podróżować i wyciskać z życia ile się da! Jest to mega pigułka najważniejszych informacji i miejsc! Czaaaad!! 🔥🔥🔥🔥",
    "Świetna, mnóstwo przydatnych treści. Zakupiłam już dzięki niej i trickom z e-bookow dwa wyjazdy pierwszy w ten piątek a kolejny w kwietniu. Poznałam także ciekawych twórców. Nie żałuję wydanych pieniędzy. ",
    "To mój najlepszy zakup e-booków ever! Niesamowita ilość treści. A dodatkowo serce rośnie jak ludzie i twórcy się wspierają i łączą siły 🫶🏻 Dzięki Wam za to! ",
    "Swietna sprawa! Wiele ebooków które chciałam o tak kupić, a dzięki paczce poznałam też kilku nowych twórców ❤️",
    "Świetna! Niesamowita! Jest kilka ebooków, które chciałam zakupić. Kilka, o których nawet nie słyszałam a wydają się ultra ciekawe. I ta cena😮",
    "Jestem mile zaskoczona tym pomysłem, uważam paczkę za super rozwiązanie. Dzięki niej poznałam wielu nowych twórców ",
];

export default testimonialsList;