import React, { useState } from 'react';
import Modal from '@/components/Modal';
import Avatar from '@/components/Avatar';
import { ReactComponent as MoreIcon } from '@/assets/more_icon.svg';
import {
    ContestImageWrapper,
    ContestPrizeAvatarWrapper,
    ContestPrizeFooter,
    ContestPrizeHeadline,
    ContestPrizeItem,
    ContestPrizeItemQuantity
} from '../styled';
import PrizeDetails from './PrizeDetails';
import { translations } from '../constants/translations';

export const PrizeItem = (
    {
        variant,
        prizeQuantity,
        prizeName,
        prizeImages,
        fullDescription,
        sponsorAvatar,
        sponsorName,
        sponsorLink,
        sponsorLinkWWW,
        isDisplayFullDescriptionEnabled
    }
) => {
    const [ isDetailsOpened, setOpenDetails ] = useState( false );
    const handleOpenModal = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push( {
            'event': 'button_click',
            'dataId': 'prize-button-details',
            'dataValue': sponsorName,
        } );
        setOpenDetails( true );
    };

    const handleCloseModal = () => {
        setOpenDetails( false );
    };
    return (
        <>
            <ContestPrizeItem
                variant={ variant }
                { ...( isDisplayFullDescriptionEnabled
                    ? { onClick: handleOpenModal, as: 'button' }
                    : { href: sponsorLink, target: '_blank' } ) }
            >
                <ContestPrizeItemQuantity variant={ variant }>
                    { prizeQuantity }
                </ContestPrizeItemQuantity>
                <ContestImageWrapper>
                    <ContestPrizeAvatarWrapper>
                        <Avatar src={ sponsorAvatar }
                                title={ sponsorName }/>
                    </ContestPrizeAvatarWrapper>
                    <img src={ prizeImages[ 0 ] }
                         alt={ prizeName }/>
                </ContestImageWrapper>
                <ContestPrizeHeadline>
                    { prizeName }
                </ContestPrizeHeadline>
                <ContestPrizeFooter>
                    { isDisplayFullDescriptionEnabled
                        ? <>{ translations.contestButtonShowMore } <MoreIcon/> </>
                        : sponsorName }
                </ContestPrizeFooter>
            </ContestPrizeItem>
            <Modal
                open={ isDetailsOpened }
                onClose={ handleCloseModal }
                customStyles={ {
                    modal: { maxWidth: '95%' },
                } }
            >
                <PrizeDetails
                    prizeImages={ prizeImages }
                    prizeName={ prizeName }
                    variant={ variant }
                    sponsorAvatar={ sponsorAvatar }
                    sponsorName={ sponsorName }
                    sponsorLink={ sponsorLink }
                    sponsorLinkWWW={ sponsorLinkWWW }
                    fullDescription={ fullDescription }
                />
            </Modal>
        </>
    );
};


export default PrizeItem;
