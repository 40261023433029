export const getSize = ( { theme, type } ) => {
    switch ( type ) {
        case 'primary':
            return theme.fontSizeM;
        case 'secondary':
            return theme.fontSizeXXL;
        case 'attention':
            return theme.fontSizeXXXL;
        default:
            return theme.fontSizeXXL;
    }
};

export default getSize;
