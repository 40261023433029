import styled from 'styled-components';


export const ContestImageWrapper = styled.div`
    min-height: 200px;
    width: 100%;
    position: relative;
    z-index: 1;
    
   > img {
        width: 100%;
        border-radius: ${({ theme }) => theme.borderRadiusM } ${({ theme }) => theme.borderRadiusM } 0 0;
    }
`;

export default ContestImageWrapper;
