import styled from 'styled-components';
import { Col } from '@/components/Layout'

export const ContestsColumn = styled(Col)`

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        width: 50%;
        padding: 0 10px;
    }
`;

export default ContestsColumn;
