import styled from 'styled-components';


const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: ${ ( { theme } ) => theme.spacing08 };
    background: ${ ( { theme } ) => theme.ui04 };
    border-top: 2px solid ${ ( { theme } ) => theme.ui01 };

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        display: none;
    }
`;

export default FooterWrapper;
