import React, {useState} from 'react';

import Avatar from '@/components/Avatar';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import {Col, Row} from '@/components/Layout';
import {TextNoWrap} from '@/components/Text';
import FeatureBox from '@/components/FeatureBox';

import ebooksList from '@/data/ebooksList';
import {paths, sortingOrder} from '@/constants';
import {getUniqueByProperty, sortByProperty} from '@/utils';

import translations from '../constants/translations';


const Authors = () => {
        const maxElementsInRow = 4;
        const paginationStep = 8;
        const [pagination, setPage] = useState(paginationStep);
        const sortedEbookList = sortByProperty({
            list: ebooksList,
            prop1: 'rank',
            prop2: 'author',
            order: sortingOrder.ASC
        });
        const authors = getUniqueByProperty(sortedEbookList, 'author');
        const isDisplayedAll = pagination <= authors.length;

        const handleOnShowMore = () => {
            setPage(pagination + paginationStep);
        };

        return (
            <Section>
                <Headline subHeading={translations.authorsSubHeadline}>
                    {translations.authorsHeadline01}
                    <TextNoWrap>{translations.authorsHeadline02}</TextNoWrap>
                </Headline>
                <Row>
                    {authors.slice(0, pagination).map((item, index) => {
                        // Check if `author`, `profileImg`, and `profileLink` are arrays
                        const isArray = Array.isArray(item.author);
                        const authorsArray = isArray ? item.author : [item.author];
                        const profileImgs = isArray ? item.profileImg : [item.profileImg];
                        const profileLinks = isArray ? item.profileLink : [item.profileLink];

                        return authorsArray.map((author, subIndex) => {
                            const profileImgPath = `${paths?.AUTHORS_AVATAR}/${profileImgs[subIndex]}`;
                            const profileLink = profileLinks[subIndex];

                            return (
                                <Col
                                    number={pagination < maxElementsInRow ? pagination : maxElementsInRow}
                                    key={`${index}-${subIndex}`}
                                >
                                    <FeatureBox
                                        dataId="author-link"
                                        dataValue={author}
                                        as="a"
                                        img={
                                            <Avatar src={profileImgPath} title={author} />
                                        }
                                        title={`@${author}`}
                                        link={profileLink}
                                    />
                                </Col>
                            );
                        });
                    })}

                </Row>
                {isDisplayedAll && (
                    <Row>
                        <Spacer size="small"/>
                        <Button variant="secondary" dataId="authors-button-show-more" onClick={handleOnShowMore}>
                            {translations.authorsButtonShowMore}
                        </Button>
                    </Row>
                )
                }
            </Section>
        );
    }
;

export default Authors;
