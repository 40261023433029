import { Modal as ReactModal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ReactComponent as CloseIcon } from '@/assets/close_icon.svg';
import { ButtonClose } from '../styled';

const Modal = ( props ) => {
    const modalStyles = {
        modal: {
            padding: '90px 0',
            borderRadius: '10px',

            ...props?.customStyles?.modal,

        },
        closeIcon: {
            ...props?.customStyles?.closeIcon
        },

    }
    return (
        <ReactModal
            closeIcon={
                <ButtonClose dataId="button-modal-close" onClick={ props.onRequestClose }>
                    <CloseIcon/>
                </ButtonClose>
            }
            styles={modalStyles}
            { ...props }
            >
            { props.children }
        </ReactModal>
    );
};

export default Modal;
