import React from 'react';

import TextWrapper from './styled/TextWrapper';

const Text = ({align = 'left', size, children, color}) => (
    <TextWrapper
        align={align}
        color={color}
        size={size}
    >
        {children}
    </TextWrapper>);


export default Text;
