import styled from 'styled-components';

export const AvatarGroup = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing09 };
`;

export default AvatarGroup;
