import { ebookStats } from '@/constants';
import { SITE_NAME } from '@/constants/globals';
export const translations = {
    ebooksHeadline: `Odkryj Cały Świat`,
    ebooksSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    ebooksIntroText01: `W tym dokumencie znajduje się 56 e-booków do pobrania. Pamiętaj proszę, że pozostałe e-booki pobierzesz z linku, który znajduje się w mailu. Na pobranie wszystkich e-booków masz 60 dni!`,
    ebooksPages: `str.`,
    ebooksPrice: `Cena regularna: `,
    ebooksButtonShowMore: `ZOBACZ WIĘCEJ`,
    ebooksButtonDownload: `POBIERZ E-BOOK`,
    ebooksButtonDownloadAll: `POBIERZ 56 E-BOOKÓW`,
    ebookTestimonials: [
        `Gorąco polecam całą paczkę ${SITE_NAME}, taka okazja naprawdę rzadko się trafia!`,
        `Zdecydowanie polecam cały zestaw ${SITE_NAME} To prawdziwa gratka, której okazje nie spotyka się często!`,
        `Zdecydowanie zachęcam do skorzystania z pełnego zestawu ${SITE_NAME} To wyjątkowa okazja, którą napotyka się rzadko.`,
        `To jest WOW! Takiego paku ebooków podróżniczych jeszcze nie było.`,
        `Mega uwielbiam! To niesamowite! Taki zestaw ebooków podróżniczych to absolutna nowość.`,
        `To jest SZTOS! Okazja jakiej jeszcze nie było, żeby zgarnąc taki pack!`,
        `Naprawdę polecam ${SITE_NAME} - to absolutna perełka! Rzadko zdarza się taka okazja!`,
        `Jeśli szukasz czegoś wyjątkowego, zdecydowanie polecam zestaw ${SITE_NAME}.`,
        `To prawdziwa okazja! ${SITE_NAME} to absolutny must-have!`,
        `To okazja, której nie można przegapić!`,
        `Z czystym sumieniem polecam zestaw ${SITE_NAME}.`,
        `To wyjątkowa okazja dla każdego podróżnika!`,
        `Jeśli chcesz znaleźć coś naprawdę wartościowego, sięgnij po ${SITE_NAME}. To okazja, której nie spotyka się często!`,
        `${SITE_NAME} to prawdziwy skarb! Gorąco polecam każdemu, kto szuka wyjątkowych okazji!`,
        `Nie mogę się oprzeć, aby nie polecić ${SITE_NAME}. To naprawdę wyjątkowa okazja!`,
        `To naprawdę wyjątkowa okazja! Z całego serca polecam zestaw ${SITE_NAME}.`,
        `${SITE_NAME}! To coś, co naprawdę warto mieć!`,
        `${SITE_NAME} to prawdziwy klejnot. Gorąco polecam każdemu!`,
        `Zdecydowanie polecam zestaw ${SITE_NAME}. To niepowtarzalna okazja, której nie można przegapić!`,
        `Jeśli chcesz zrobić sobie prezent, sięgnij po ${SITE_NAME}. To naprawdę niesamowita okazja!`,
        `Nie mogę się doczekać, aby polecić ${SITE_NAME} wszystkim moim znajomym. To wyjątkowa okazja!`,
        `${SITE_NAME} to coś, co naprawdę warto mieć. Zdecydowanie polecam!`,
        `Polecam zestaw ${SITE_NAME} z całego serca. To okazja, która rzadko się zdarza!`,
        `Jeśli chcesz znaleźć coś naprawdę wyjątkowego, postaw na ${SITE_NAME}!`,
        `${SITE_NAME} to prawdziwy skarb dla miłośników podróży. Gorąco polecam!`,
        `Nie mogę się nadziwić, jakie okazje oferuje zestaw ${SITE_NAME}. Zdecydowanie polecam!`,
        `${SITE_NAME} to absolutny hit! Polecam każdemu, kto chce oszczędzić na podróżowaniu!`,
        `Z czystym sumieniem polecam zestaw ${SITE_NAME}. To prawdziwa perełka!`,
        `Zastanawiasz się jak tanio podróżować? Wybierz ${SITE_NAME}!`,
        `Gdzie polecieć? Co można zwiedzić? Te odpowiedzi znajdziesz w ${SITE_NAME}!`,
        `Zdecydowanie polecam cały zestaw ${SITE_NAME}. To niezwykła okazja, którą warto wykorzystać!`,
        `${SITE_NAME} to absolutny must-have dla każdego podróżnika. Gorąco polecam!`,
        `Jeśli szukasz wyjątkowych okazji, zestaw ${SITE_NAME} jest właśnie dla Ciebie. Naprawdę warto!`,
        `${SITE_NAME} to prawdziwy skarb dla miłośników podróży. Polecam każdemu!`,
        `Nie mogę się doczekać, aby podzielić się moją radością związana z zestawem ebooków ${SITE_NAME}. Jest to rzecz, którą musisz mieć!`,
        `${SITE_NAME} to niezastąpiona pomoc dla każdego, kto uwielbia podróże. Zdecydowanie polecam!`,
        `Z całego serca polecam zestaw ${SITE_NAME}. To doskonała inwestycja w Twoje podróże!`,
        `Jeśli chcesz podróżować z wygodą i oszczędzać, sięgnij po zestaw ${SITE_NAME}. To naprawdę warto!`,
        `${SITE_NAME} to idealny towarzysz podróży. Polecam każdemu, kto ceni wygodę i oszczędność!`,
        `Zestaw ${SITE_NAME} to coś więcej niż zestaw podróżny - to klucz do wygodnych i oszczędnych podróży. Gorąco polecam!`,
        `Nie mogę się doczekać, aby podzielić się moim odkryciem - zestawem ${SITE_NAME}. To prawdziwa perełka!`,
        `${SITE_NAME} to niezawodny partner w podróży. Polecam go każdemu, kto ceni sobie komfort i oszczędność!`,
        `Z całego serca polecam zestaw ${SITE_NAME}. To inwestycja, która szybko się zwróci!`,
        `${SITE_NAME} to prawdziwa rewolucja w podróżowaniu. Polecam każdemu, kto szuka wygodnych i oszczędnych rozwiązań!`,
        `Jeśli chcesz uczynić swoje podróże bardziej komfortowymi i oszczędnymi, postaw na zestaw ${SITE_NAME}. Gorąco polecam!`,
        `${SITE_NAME} to niezbędne wyposażenie każdego podróżnika. Zdecydowanie warto zainwestować!`,
        `Z całego serca polecam zestaw ${SITE_NAME}. To produkt, który spełni oczekiwania nawet najbardziej wymagających podróżników!`,
        `Jeśli szukasz skutecznego sposobu na oszczędzanie podczas podróży, zestaw ${SITE_NAME} jest dla Ciebie. Naprawdę warto!`,
        `${SITE_NAME} to klucz do udanych podróży. Gorąco polecam każdemu, kto ceni sobie wygodę i oszczędność!`,
        `Zdecydowanie polecam zestaw ${SITE_NAME}. To produkt, który sprawi, że Twoje podróże staną się jeszcze przyjemniejsze i bardziej oszczędne!`,
    ],
};

export default translations;
