import React from 'react';

import FeatureBox from '@/components/FeatureBox';
import { Col, Row } from '@/components/Layout';
import Headline from '@/components/Headline';
import Section from '@/components/Section';

import GlobeIcon from '@/assets/globe_icon.gif';
import PiggyIcon from '@/assets/piggy_icon.gif';
import BookIcon from '@/assets/book_icon.gif';
import FavIcon from '@/assets/fav_icon.gif';

import translations from '../constants/translations';

const Advantages = () => (
    <Section>
        <Headline subHeading={ translations.advantagesSubHeadline }>{ translations.advantagesHeadline }</Headline>
        <Row>
            <Col number={ 4 }>
                <FeatureBox
                    img={ BookIcon }
                    title={ translations.advantagesItemTitle04 }
                    text={ translations.advantagesItemText04 }
                />
            </Col>
            <Col number={ 4 }>
                <FeatureBox
                    img={ GlobeIcon }
                    title={ translations.advantagesItemTitle01 }
                    text={ translations.advantagesItemText01 }
                />
            </Col>
            <Col number={ 4 }>
                <FeatureBox
                    img={ PiggyIcon }
                    title={ translations.advantagesItemTitle02 }
                    text={ translations.advantagesItemText02 }
                    isFeatured
                />
            </Col>
            <Col number={ 4 }>
                <FeatureBox
                    img={ FavIcon }
                    title={ translations.advantagesItemTitle03 }
                    text={ translations.advantagesItemText03 }
                />
            </Col>
        </Row>
    </Section>
);


export default Advantages;
