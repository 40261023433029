import styled from 'styled-components';

export const EbookCover = styled.img`
    max-height: 800px;
    max-width: 100%;
    border-radius: ${ ( { theme } ) => `${ theme.borderRadiusS }` };
    
`;

export default EbookCover;
