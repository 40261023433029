import React from 'react';

import RowWrapper from '../styled/RowWrapper';

const Row = ({justifyContent, children, isMobileReverted, direction}) => {
    const firstLevelChildren = React.Children.toArray(children).filter(child =>
        React.isValidElement(child)
    );
    const numberOfChildren = firstLevelChildren.length;
    return (
        <RowWrapper
            direction={direction}
            justifyContent={justifyContent}
            numberOfChildren={numberOfChildren}
            isMobileReverted={isMobileReverted}
        >
            {children}
        </RowWrapper>
    );
};

export default Row;
