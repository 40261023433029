export const getColor = ( { theme, colorVariant } ) => {
    switch ( colorVariant ) {
        case 'orange':
            return '#e4935a';
        case 'green':
            return theme.text02;
        default:
            return theme.text02;
    }
};

export default getColor;
