import styled from 'styled-components';

export const ContestPrizeHeadline = styled.h3`
    color: ${ ( { theme } ) => theme.text03 };
    text-transform: uppercase;
    font-size: ${ ( { theme } ) => theme.fontSizeXS };
    text-align: center;
    padding: ${ ( { theme } ) => theme.spacing07 } ${ ( { theme } ) => theme.spacing09 } ${ ( { theme } ) => theme.spacing08 };
    
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        padding: ${ ( { theme } ) => theme.spacing08 } ${ ( { theme } ) => theme.spacing09 };
    }
`;

export default ContestPrizeHeadline;
