import React from 'react';

import Avatar from '@/components/Avatar';
import {CommentSpeechBubble, CommentSpeechBubbleRight, CommentWrapper} from '../styled';

const Comment = ({
                     avatar,
                     author,
                     orientation = 'left',
                     text,
                 }) => (
    <CommentWrapper
        orientation={orientation}
    >
        <Avatar title={author} src={avatar}/>
        {orientation === 'left' ? <CommentSpeechBubble>{text}</CommentSpeechBubble> :
            <CommentSpeechBubbleRight>{text}</CommentSpeechBubbleRight>}
    </CommentWrapper>);

export default Comment;
