import styled from 'styled-components';

const PreviewImage = styled.div`
    margin-bottom: ${ ( { theme } ) => theme.spacing08 };
    
    > img {
        width: 100%;
        min-height: 150px;
        border-radius: ${ ( { theme } ) => `${ theme.borderRadiusM } ${ theme.borderRadiusM } 0 0` };
    }
`;

export default PreviewImage;
