import styled from 'styled-components';
import { getBackgroundColor } from '../utils/getBackgroundColor'

export const ContestPrizeItemQuantity = styled.div`
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    height: 140px;
    width: 140px;
    background: ${ ( { variant  } ) => getBackgroundColor( { variant  } ) };
    border-radius: 100%;
    border: 8px solid ${ ( { theme  } ) => theme.ui01 };
    color: ${ ( { theme  } ) => theme.text03 };
    font-size: 70px;
    font-weight: ${ ( { theme  } ) => theme.fontWeightBold };;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        height: 100px;
        width: 100px;
        top: -50px;
        font-size: 50px;
    }
`;

export default ContestPrizeItemQuantity;
