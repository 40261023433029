import React, { useState } from 'react';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Preview from '@/components/Preview';
import Headline from '@/components/Headline';
import Section from '@/components/Section';
import { Col, Row } from '@/components/Layout';

import ebooksList from '@/data/ebooksList';
import { currencies, paths, sortingOrder } from '@/constants';

import translations from '../constants/translations';
import { filterByProperty, sortByProperty } from '@/utils';
import { DOWNLOAD_URL } from '@/constants/globals';

const EbookList = ({
    showHeadline = true,
    showDownloadButton = false,
    paginationStep = 6,
    filterBy = [],
                   }) => {
    const [ pagination, setPage ] = useState( paginationStep );
    const isDisplayedAll = pagination <= ebooksList.length;
    const userLocale = navigator.language || navigator.userLanguage;
    const filteredEbookLists = filterByProperty({ data: ebooksList, conditions: filterBy});
    const sortedEbookList = sortByProperty( {
        list: filteredEbookLists,
        prop1: 'rank',
        prop2: 'author',
        order: sortingOrder.ASC
    });
    const handleOnShowMore = () => {
        setPage( pagination + paginationStep );
    };

    return (
        <Section variant="dark" id="ebooks">
            {showHeadline && <Headline subHeading={ translations.ebooksSubHeadline }>{ translations.ebooksHeadline }</Headline>}
            <Row>
                { sortedEbookList.slice( 0, pagination ).map( ( item, index ) => {
                    const ebookCoverPath = item?.img && `${ paths?.E_BOOKS_COVER }/${ item?.img }`;
                    const ebookPages = item?.pages ? `${ item?.pages } ${ translations?.ebooksPages }` : null;
                    const ebookPrice = item?.regularPrice.toLocaleString( userLocale, {
                        style: 'currency',
                        currency: currencies?.PLN,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    } );
                    return (
                        <Col number={ 3 } key={ index }>
                            <Preview
                                index={ index }
                                avatar={ item?.profileImg }
                                cover={ ebookCoverPath }
                                description={ item?.ebookDescription }
                                title={ item?.eBookTitle }
                                location={ item?.location }
                                eBookType={ item?.eBookType}
                                author={ item?.author }
                                pages={ ebookPages }
                                price={ ebookPrice }
                            />
                            {
                                showDownloadButton && <Button as="a" href={DOWNLOAD_URL}>{ translations.ebooksButtonDownload }</Button>
                            }
                        </Col>
                    );
                } ) }
            </Row>
            { isDisplayedAll && (
                <Row>
                    <Spacer size="small"/>
                    <Button variant="secondary" dataId="ebook-button-show-more" onClick={ handleOnShowMore }>
                        { translations.ebooksButtonShowMore }
                    </Button>
                </Row>
            ) }
        </Section>
    );
};

export default EbookList;
