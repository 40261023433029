import styled from 'styled-components';

export const ContestStepContent = styled.div`
    display: flex;
    padding: ${( { theme }) => theme.spacing06 };
    flex-direction: column;
    align-items: flex-start;
    
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        align-items: center;
    }
`;

export default ContestStepContent;
