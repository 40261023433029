import styled from 'styled-components';

export const ContestPrizeFooter = styled.div`
    background: rgb(0, 0, 0, 0.2);
    color: ${ ( { theme } ) => theme.text03 };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeXXXS };
    border-top: 1px dashed rgb(255 255 255 / 64%);
    border-radius: 0 0 ${ ( { theme } ) => theme.borderRadiusM } ${ ( { theme } ) => theme.borderRadiusM };
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: ${ ( { theme } ) => theme.spacing07 };
    
    svg {
        height: 36px;
        fill:  ${ ( { theme } ) => theme.ui01 };;
        padding: 0 4px;
    }
`;

export default ContestPrizeFooter;
