import { EBOOK_TOTAL_EBOOKS, TRAVEL_PACK_SIZE } from '@/constants/ebookStats';
import { SITE_CONTACT_EMAIL, SITE_NAME, SITE_SOCIAL_LINK_IG } from '@/constants/globals';

export const translations = {
    faqHeadline: `FAQ`,
    faqList: [
        {
            question: `Ile mam czasu na pobranie e-booków po zakupie ${ SITE_NAME }?`,
            answer: `Po zakupie paczki ${ SITE_NAME } otrzymasz e-mail z dokładnymi instrukcjami dotyczącymi pobierania e-booków. Pamiętaj jednak, że masz tylko 60 dni od daty zakupu, aby pobrać wszystkie e-booki z paczki. Po upływie tego okresu, nie będziesz miał możliwości pobrania plików! Zalecamy pobranie e-booków jak najszybciej po zakupie, aby móc swobodnie z nich korzystać w przyszłości. `,
        },
        {
            question: `Jakie treści znajdę w paczce?`,
            answer: `W paczce znajdują się przewodniki po danych miastach, regionach i krajach. Dostaniesz wszelkie podróżnicze “know how” - począwszy od kupienia lotów czy bookowania hoteli, po podróżnicze tricki na “tanie podróżowanie”. Są także gastro miejscówki, przepisy z różnych stron świata, czy nawet biznes w podróży - czyli jak zmienić pracę i zarabiać w trasie! Dodatkowo znajdziesz tam e-booki językowe. Język angielski i grecki w podróży.`,
        },
        {
            question: `O co dokładnie chodzi z paczką ${ SITE_NAME }?`,
            answer: `${ SITE_NAME } to unikalna okazja, w której oferujemy ${ EBOOK_TOTAL_EBOOKS } produktów cyfrowych dostępnych jako jedna paczka. Pamiętaj, że pierwsza kampania trwa tylko 10 dni.`,
        },
        {
            question: `Skąd pomysł na paczkę ${ SITE_NAME }?`,
            answer: `Od zawsze jesteśmy zdania, że lepiej się łączyć niż dzielić, a we współpracy tkwi siła. Nasz pomysł spodobał się Twórcom Internetowym i dzięki temu teraz możemy zaproponować Wam tak kompleksowy produkt, o ogromnej wartości, w niezwykle przystępnej cenie.`,
        },
        {
            question: `Czy warto kupić TravelPack?`,
            answer: `${ SITE_NAME } złożony jest z kilkudziesięciu wartościowych e-booków podróżniczych autorstwa cenionych twórców internetowych. Każdy e-book to efekt minimum kilkumiesięcznej pracy poszczególnego autora. <br /><br />W TravelPack znajdziesz przewodniki po miastach i krajach, fotoprzewodniki, wszelkie podróżnicze know-how, kuchnie świata, opowieści z podróży, podróże z dziećmi czy biznes w podróży. <br /><br />Nawet jeśli zdecydujesz się skorzystać tylko z kilku e-booków z naszej paczki, oszczędzisz znacznie więcej pieniędzy w porównaniu do zakupu każdego e-booka osobno. Dzięki tej akcji stajesz się posiadaczem kompletnej biblioteki podróżniczej, a paczka jest nie tylko atrakcyjna, ale także opłacalna cenowo!`,
        },
        {
            question: `Dlaczego nie jest dostępna inna forma płatności niż karta?`,
            answer: `Korzystamy z zagranicznych programów sprzedażowych, które niestety nie mają dostępnych polskich form płatności, takich jak BLIK czy przelewy24. Mimo braku tych opcji, chcielibyśmy zapewnić, że płatności kartą są bezpieczne i spełniają najwyższe standardy bezpieczeństwa transakcji online. Dodatkowo można dokonać płatności za pomocą ApplePay.`,
        },
        {
            question: `Czy przysługuje mi prawo do aktualizacji e-booków?`,
            answer: `Nie, prawa do aktualizacji e-booków w ramach ${ SITE_NAME } nie przysługują. Powód tego ograniczenia wynika z charakteru kampanii, która obejmuje sprzedaż i dystrybucję e-booków w określonym czasie. Z racji jednorazowego charakteru promocji oraz atrakcyjnej ceny paczki, niestety nie jesteśmy w stanie zapewnić możliwości aktualizacji treści po zakończeniu kampanii. `,
        },
        {
            question: `Jak mogę skontaktować się w razie pytań lub problemów?`,
            answer: `Jesteśmy dostępni od adresem <a href="mailto:${ SITE_CONTACT_EMAIL }?subject=Potrzebuję pomocy">${ SITE_CONTACT_EMAIL }</a> oraz na naszym instagramie ${ SITE_SOCIAL_LINK_IG }. Chętnie odpowiemy na wszystkie pytania i udzielimy niezbędnej pomocy. Czekamy na Ciebie podczas 10-dniowej sprzedaży ${ SITE_NAME } 🌍✈️.`,
        },
        {
            question: `Dlaczego muszę czekać na pobranie plików po zapłacie?`,
            answer: `Po dokonaniu płatności za e-booki, musisz poczekać chwilę na pobranie plików. To dlatego, że po zweryfikowaniu płatności nanosimy na nie unikalną licencję, która jest powiązana z numerem twojego zamówienia. Proces ten polega na przetworzeniu każdej strony e-booka, aby dodać odpowiednie informacje licencyjne. Zazwyczaj zajmuje to mniej niż dwie minuty. Dziękujemy za cierpliwość!`,
        },
        {
            question: `Ile miejsca zajmują wszystkie e-booki?`,
            answer: `To cyfrowy produkt, więc nie zajmuje miejsca na Twojej półce 😉. Całkowity rozmiar wszystkich plików e-booków wynosi ${ TRAVEL_PACK_SIZE }, w związku z czym tyle minimalnie wolnego miejsca potrzebujesz na swoim urządzeniu.`,
        },
        {
            question: `W jakim formacie są zapisane e-booki?`,
            answer: `Po zakupie otrzymasz ${ EBOOK_TOTAL_EBOOKS -1 } e-booków w formacie PDF oraz jeden zestaw presetów do Lightroom wraz z instrukcją instalacji.`,
        },
        {
            question: `Czy mogę otrzymać fakturę za zakup paczki e-booków ${ SITE_NAME }?`,
            answer: `Aby otrzymać fakturę, prosimy o przesłanie numeru zamówienia oraz danych do faktury na nasz adres e-mail: ${ SITE_CONTACT_EMAIL }. Po otrzymaniu tych informacji przygotujemy i prześlemy fakturę najszybciej, jak to możliwe.`,
        },
    ]
};

export default translations;
