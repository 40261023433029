import React from 'react';

import Main from '@/components/Main';
import Header from '@/components/Header';
import Spacer from "@/components/Spacer";
import Footer from '@/components/Footer';
import StickyCountdown from '@/components/StickyCountdown';

import ContestPrize from 'src/views/ContestPrize';
import ContestSteps from '@/views/ContestSteps';

export const Contest = () => (
    <>
        <Header/>
        <Main>
            <ContestPrize isDisplayFullDescriptionEnabled />
            <ContestSteps />
            <Spacer />
        </Main>
        <Footer/>
        <StickyCountdown/>
    </>
);


export default Contest;
