export const getFontFamily = ( { theme, type } ) => {
    switch ( type ) {
        case 'primary':
            return theme.fontFamilyPrimary;
        case 'secondary':
            return theme.fontFamilySecondary;
        case 'attention':
            return theme.fontFamilySecondary;
        default:
            return theme.fontFamilyPrimary;
    }
};

export default getFontFamily;
