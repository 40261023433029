import { sortingOrder } from '@/constants';

export const sortByProperty = ({ list = [], prop1, prop2, order = sortingOrder.ASC }) => {
    const sortOrder = order === sortingOrder.DESC ? -1 : 1;

    return list.slice().sort((a, b) => {
        const propA1 = a[prop1];
        const propB1 = b[prop1];

        if (propA1 < propB1) {
            return -1 * sortOrder;
        } else if (propA1 > propB1) {
            return 1 * sortOrder;
        } else {
            // If prop1 values are equal, sort by prop2
            const propA2 = Array.isArray(a[prop2]) ? JSON.stringify(a[prop2]) : a[prop2];
            const propB2 = Array.isArray(b[prop2]) ? JSON.stringify(b[prop2]) : b[prop2];

            return propA2.localeCompare(propB2) * sortOrder;
        }
    });
};


export default sortByProperty;
