import styled from 'styled-components';

export const ContestStepCircle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    min-width: 120px;
    border-radius: 100%;
    color: ${( { theme }) => theme.text03 };
    background-color: ${( { theme }) => theme.ui06 };
    font-size: ${( { theme }) => theme.fontSizeXXL };
    font-weight: ${( { theme }) => theme.fontWeightBold };
    letter-spacing: 0;
    border: 6px solid ${( { theme }) => theme.ui04 };
`;

export default ContestStepCircle;
