import styled from 'styled-components';


export const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: ${ ( { theme } ) => theme.spacing05 };
    margin-bottom: ${ ( { theme } ) => theme.spacing02 };
    position: relative;
    z-index: 2;
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        flex-direction: column;
        padding-bottom: 300px;
    }
`;

export default SummaryWrapper;
