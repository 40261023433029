import React from 'react';

import FeatureBox from '@/components/FeatureBox';
import Headline from '@/components/Headline';
import Section from '@/components/Section';
import { Col, Row } from '@/components/Layout';

import CardIcon from '@/assets/credit_card_icon.gif';
import EmailIcon from '@/assets/email_check_icon.gif';
import DownloadIcon from '@/assets/download_icon.gif';

import translations from '../constants/translations';


const Steps = () => {
    return (
        <Section variant="dark">
            <Headline subHeading={ translations.stepsSubHeadline }>{ translations.stepsHeadline }</Headline>
            <Row>
                <Col number={ 3 }>
                    <FeatureBox
                        img={ CardIcon }
                        title={ translations.stepsItemTitle01 }
                        text={ translations.stepsItemText01 }
                    />
                </Col>
                <Col number={ 3 }>
                    <FeatureBox
                        img={ EmailIcon }
                        title={ translations.stepsItemTitle02 }
                        text={ translations.stepsItemText02 }
                        isFeatured
                    />
                </Col>
                <Col number={ 3 }>
                    <FeatureBox
                        img={ DownloadIcon }
                        title={ translations.stepsItemTitle03 }
                        text={ translations.stepsItemText03 }
                    />
                </Col>
            </Row>
        </Section>
    );
};

export default Steps;
