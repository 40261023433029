import { SITE_NAME, CONTEST_SOCIAL_LINK } from '@/constants/globals';
import { EBOOK_TOTAL_EBOOKS } from '@/constants/ebookStats';
import { ASSETS_DOCS } from '@/constants/paths';
export const translations = {
    contestHeadline: `KONKURS`,
    contestSubHeadline: `Z ${SITE_NAME} Po Nagrody`,
    contestStepsHeadline: `Jak Wziąć Udział W Konkursie`,
    contestStepsSubHeadline: `4 kroki żeby wygrać nagrody`,
    contestStepText: `KROK`,
    contestStep01Headline: `Kup ${SITE_NAME}`,
    contestStep02Headline: `Polub Post Konkursowy`,
    contestStep03Headline: `Udostępnij Post Konkursowy`,
    contestStep04Headline: `Napisz Komentarz Pod Postem`,
    contestStep01Text: `ciesz się ${EBOOK_TOTAL_EBOOKS} ebookami i wygraj nagrody`,
    contestStep02Text: `na NASZYM profilu na <a href="${CONTEST_SOCIAL_LINK}" target="_blank">Instagram</a>`,
    contestStep03Text: `na SWOIM profilu na <a href="${CONTEST_SOCIAL_LINK}" target="_blank">Instagram</a>.`,
    contestStep04Text: `odpowiadając na pytanie: <br/><strong>„Kogo byś zabrał/a na wakacje? Gdzie i dlaczego?”</strong>`,
    contestStepsTextMain01: `Spośród najciekawszych komentarzy wybierzemy zwycięzców. Szczegółowe informacje na temat konkursu znajdziesz w <a href="${ASSETS_DOCS}/travel_pack_regulamin_konkursu.pdf" target="_blank">regulaminie konkursu</a>.`,
    contestTextMain01: `Kupując paczkę masz szansę wygrać niesamowite nagrody! Wyobraź sobie <strong>voucher o wartości 1200 zł</strong> na wymarzony lot w dowolne miejsce, niezapomniany <strong>pobyt w urokliwych domkach</strong> nad jeziorem Nyskim oraz wiele innych fantastycznych niespodzianek. <br/><br/> To wyjątkowa okazja, by spełnić podróżnicze marzenia i stworzyć wspomnienia na całe życie!`,
    contestButton: 'Weź Udział W Konkursie',
};

export default translations;
