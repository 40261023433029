import styled from 'styled-components';


export const PricingWrapper = styled.div`
    position: relative;
    bottom: -40px;
    margin-top: -30px;
    width: 100%;
`;

export default PricingWrapper;
