import { createGlobalStyle } from 'styled-components';
import 'normalize.css';

const Normalize = createGlobalStyle`

    body {
        font-family: ${ ( { theme } ) => theme.fontFamilyPrimary };
        font-size: ${ ( { theme } ) => theme.fontSizeS };
        font-weight: ${ ( { theme } ) => theme.fontWeightRegular };
    }

    a {
        color: ${ ( { theme } ) => theme.text01 };
        text-decoration: underline;
    }

    p a {
        text-decoration: underline;
        color: ${ ( { theme } ) => theme.text01 };
    }

    * {
        box-sizing: border-box;
    }

    button {
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        color: inherit;
    }
`;

export default Normalize;
