import styled from 'styled-components';
import { ASSETS } from '@/constants/paths';
import { getColor, getFontFamily, getSize }  from '../utils'

const HeadlineText = styled.h1`
    font-size: ${ ( { theme, type } ) => getSize({ theme, type })};
    font-family: ${ ( { theme, type = 'secondary' } ) => getFontFamily({ theme, type } ) };
    line-height: 1;
    color: ${ ( { theme, colorVariant } ) => getColor({ theme, colorVariant } ) };
    padding: 0;
    margin: ${ ( { theme } ) => theme.spacing08 } 0 0 0;
    
    em {
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-style: normal;
        &:before {
            content: '';
            position: absolute;
            bottom: -16px;
            left: -50%;
            transform: scale(0.5) translate(0, -50%);
            margin-left: -10px;
            display: block;
            width: 440px;
            height: 18px;
            background: url('${ ASSETS }/underline_decor.png') no-repeat;
        }


`;

export default HeadlineText;
