import React from 'react';

import Text from '@/components/Text';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import Spacer from '@/components/Spacer';
import { Col, Row } from '@/components/Layout';
import { SITE_NAME } from "@/constants/globals";

import { ContentWrapper} from '../styled';

const Confirmation = ({ headline, children}) => (
    <Section variant="dark">
        <Row>
            <Col number={ 2 }>
                <ContentWrapper>
                    <Headline subHeading={SITE_NAME}>
                        { headline }
                    </Headline>
                    <Text size="medium" align="center">
                        { children }
                    </Text>
                    <Spacer size="medium" />
                </ContentWrapper>
            </Col>
        </Row>
    </Section>
);


export default Confirmation;
