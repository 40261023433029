import React from 'react';

import { ASSETS } from '@/constants/paths';
import Section from '@/components/Section';
import Comment from '@/components/Comment';
import Headline from '@/components/Headline';

import translations from '../constants/translations';
import testimonialsList from '../constants/testimonialsList';
import { TestimonialsListWrapper } from '../styled';

const Testimonials = () => {

    return (
        <Section id="opinie" variant="dark">
            <Headline subHeading={ translations.testimonialsSubHeadline }>
                { translations.testimonialsHeading }
            </Headline>
            <TestimonialsListWrapper>
                { testimonialsList.map( ( item, index ) => {
                    return (
                        <Comment
                            orientation={ index % 2 === 0 ? 'left' : 'right' }
                            key={ index } text={ item }
                            avatar={ `${ ASSETS }/avatar_${ index + 1 }.gif` }
                        />
                    );
                } )
                }
            </TestimonialsListWrapper>
        </Section>
    );
};


export default Testimonials;
