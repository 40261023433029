export const typography = {
    fontFamilyPrimary: 'Poppins, serif',
    fontFamilySecondary: 'Tinos, serif',
    fontWeightRegular: '400',
    fontWeightSemiBold: '500',
    fontWeightBold: '600',
    fontSizeXXXL: '64px',
    fontSizeXXL: '46px',
    fontSizeXL: '40px',
    fontSizeL: '36px',
    fontSizeM: '24px',
    fontSizeS: '16px',
    fontSizeXS: '14px',
    fontSizeXXS: '12px',
    fontSizeXXXS: '11px',
    fontLinHeightRegular: '1.5',
};
export default typography;
