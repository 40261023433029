export const getBackgroundColor = ( { variant } ) => {
    switch ( variant ) {
        case 'blue':
            return '#204797';
        case 'orange':
            return '#e4935a';
        case 'green':
            return '#659268';
        case 'yellow':
            return '#f4bb4b';
        default:
            return '#3b6491';
    }
};
