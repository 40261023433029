import React from 'react';
import { Col, Row } from '@/components/Layout';
import { EbookDescription, EbookDetailsContentWrapper } from '@/views/Ebooks/styled';
import Spacer from '@/components/Spacer';
import Text from '@/components/Text';
import Headline from '@/components/Headline';
import Button from '@/components/Button';
import { translations as commonTranslation } from '@/constants';
import { CONTEST_SOCIAL_LINK } from '@/constants/globals';
import { ContestImageGrid } from '../styled';
import Comment from '@/components/Comment';

export const PrizeDetails = (
    {
        prizeName,
        prizeImages,
        fullDescription,
        sponsorAvatar,
        sponsorName,
        sponsorLink,
        sponsorLinkWWW,
    }
) => {

    return (
        <EbookDetailsContentWrapper>
            <Row>
                <Col number={ 3 }>
                    <ContestImageGrid>
                        {
                            prizeImages.map( ( item, index ) => (
                                <img key={ index } src={ item } alt={ prizeName }/>
                            ) ) }
                    </ContestImageGrid>
                    <Spacer size="small"/>
                </Col>
                <Col number={ 2 }>
                    <Headline subHeading={ sponsorName }>{ prizeName }</Headline>
                    <EbookDescription size="medium">
                            <span
                                dangerouslySetInnerHTML={ { __html: fullDescription } }
                            />
                        <Text size="medium">
                            { sponsorLink && <a href={sponsorLink} target="_blank" rel="noreferrer">INSTAGRAM</a>}
                            { sponsorLinkWWW && " | " }
                            { sponsorLinkWWW && <a href={sponsorLinkWWW} target="_blank" rel="noreferrer">WWW</a> }
                        </Text>
                    </EbookDescription>
                    <Spacer size="small"/>
                    <Comment
                        text={"Pssst... pamiętaj Kup, Polub, Udostępnij i Skomentuj - 'Kogo byś zabrał/a na wakacje? Gdzie i dlaczego?'"}
                        author={sponsorName}
                        avatar={sponsorAvatar}
                        orientation="left"/>
                    <Spacer size="small"/>
                    <Button
                        dataId="button-contest-details"
                        dataValue={ prizeName }
                        as="a"
                        rel="nofollow"
                        href={ CONTEST_SOCIAL_LINK }
                        isFullWidth
                    >
                        { commonTranslation.commonButtonContest }
                    </Button>

                </Col>
            </Row>
        </EbookDetailsContentWrapper>
    );
};


export default PrizeDetails;
