import { EBOOK_TOTAL_VALUE, TRAVEL_PACK_PRICE } from '@/constants/ebookStats';
import { currencies } from '@/constants';

const userLocale = navigator.language || navigator.userLanguage;

export const getBundlePrice = parseInt( TRAVEL_PACK_PRICE ).toLocaleString( userLocale, {
    style: 'currency',
    currency: currencies?.PLN,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} );

export const getRegularPrice = parseInt( EBOOK_TOTAL_VALUE ).toLocaleString( userLocale, {
    style: 'currency',
    currency: currencies?.PLN,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} );
