import React from 'react';

import { SectionContent, SectionWrapper } from '../styled';

export const Section = ( { id, variant, imgURL = '', spacing = 'medium', children } ) => {
    return (
        <SectionWrapper id={ id } variant={ variant } imgURL={imgURL}>
            <SectionContent spacing={ spacing }>{ children }</SectionContent>
        </SectionWrapper>
    );
};

export default Section;
