import styled from 'styled-components';

export const ContestStepWrapper = styled.div`
    display: flex;
    padding: ${( { theme }) => theme.spacing05 } 0;
    flex-direction: row;
    align-items: center;
    width: 100%;
    
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        flex-direction: column;
        border-bottom: 2px dotted ${( { theme }) => theme.ui09 };
    }
`;

export default ContestStepWrapper;
