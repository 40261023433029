import styled from 'styled-components';


export const SummaryImage = styled.img`
    position: absolute;
    bottom: 60px;
    right: 0;
    height: 500px;
    z-index: 1;
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        height: 300px;
        bottom: 160px;
    }
`;

export default SummaryImage;
