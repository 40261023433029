import styled from 'styled-components';


const SubscribeWrapper = styled.div`
    margin: ${ ( { theme } ) => `${ theme.spacing04 } auto`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 ${ ( { theme } ) => theme.spacing04 };
`;

export default SubscribeWrapper;
