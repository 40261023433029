import styled from 'styled-components';


export const ContestImage = styled.img`
    max-width: 60%;
    position: relative;
    top: -40px;

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        max-width: 100%;
        top: -80px;
    }
`;

export default ContestImage;
