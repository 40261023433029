import styled from 'styled-components';


const StickyFooterWrapper = styled.div`
    position: fixed;
    justify-content: space-between;
    align-items: center;
    display: ${ ( { theme, showFooter } ) => showFooter ? 'flex' : 'none' };
    bottom: ${( { isPageBottom } ) =>  isPageBottom ? '72px' : '0' };
    width: 100%;
    background-color: ${ ( { theme } ) => `${ theme.ui04 }f2` };
    padding: ${ ( { theme } ) => `${ theme.spacing09 } ${ theme.spacing06 } ${ theme.spacing07 }` };
    z-index: ${ ( { theme } ) => theme.floor100 };

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        bottom :0;
    }
`;

export default StickyFooterWrapper;
