import styled from 'styled-components';


const FooterNavigationLink = styled.button`
    background: none;
    border: none;
    outline: none;
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    text-transform: uppercase;
    cursor: pointer;
    padding: ${ ( { theme } ) => theme.spacing08 } ${ ( { theme } ) => theme.spacing07 };
    color: ${ ( { theme } ) => theme.text03 };
    transition: all ${ ( { theme } ) => `${ theme.transitionTypeDefault } ${ theme.transitionFast }` };

    &:hover,
    &:focus {
        color: ${ ( { theme } ) => theme.text03 };
        text-decoration: underline;

        &:focus {
            outline: 2px solid ${ ( { theme } ) => theme.ui01 };;
        }
`;

export default FooterNavigationLink;
