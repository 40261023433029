import React from 'react';
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Normalize from '@/components/Normalize';
import CookieConsent from '@/components/CookieConsent';
import { About, Ebooks, Faq, Home, Contest, SuccessConfirmation } from '@/pages';

import { light } from '@/theme';
import { globals, featureFlags, translations } from '@/constants';
import { removeFeatureFlag, setFeatureFlagFromUrl } from '@/utils';


const router = createBrowserRouter( [
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: 'o-travel-pack',
        element: <About />,
    },
    {
        path: 'faq',
        element: <Faq />,
    },
    {
        path: 'e-books',
        element: <Ebooks />,
    },
    {
        path: 'wygraj-nagrody',
        element: <Contest />,
    },
    {
        path: 'subscribe-confirmation',
        element: (
            <SuccessConfirmation headline={ translations.confirmationHeadlineSubscribe }>
                { translations.confirmationContentSubscribe }
            </SuccessConfirmation>
        ),
    },
    {
        path: 'potwierdzenie-zamowienia',
        element: (
            <SuccessConfirmation headline={ translations.confirmationHeadlinePurchase }>
                { translations.confirmationContentPurchase }
            </SuccessConfirmation>
        ),
    },

] );


function App() {
    const isConsentSet = localStorage.getItem( globals.COOKIE_CONSENT );
    /** ONLY FOR TEST PURPOSE **/
    setFeatureFlagFromUrl(featureFlags.FEATURE_FLAG_SHOW_BUTTON_BUY);
    removeFeatureFlag();
    /** ** **/
    return (
        <ThemeProvider theme={ light }>
            <>
                <Normalize/>
                { !isConsentSet && <CookieConsent/> }
                <RouterProvider router={ router }/>
            </>
        </ThemeProvider>
    );
}

export default App;
