export const getSize = ( { size, theme } ) => {
    switch ( size ) {
        case 'super-small':
            return theme.fontSizeXXXS;
        case 'small':
            return theme.fontSizeXXS;
        case 'medium':
            return theme.fontSizeS;
        case 'large':
            return theme.fontSizeL;
        default:
            return theme.fontSizeXXS;
    }
};

export default getSize;
