import React from 'react';

import Text from '@/components/Text';
import Button from '@/components/Button';
import { SectionContent } from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';
import FeatureBox from '@/components/FeatureBox';

import PiggyIcon from '@/assets/piggy_icon.gif';
import BookIcon from '@/assets/book_icon.gif';

import { getBundlePrice, getRegularPrice, isButtonBuyDisabled } from '@/utils';
import { ebookStats, routes, translations } from '@/constants';

import { BannerBigText, PricingHeadline, PricingPrice, PricingWrapper, SectionBanner } from '../styled';
import PricingContent from './PricingContent';

const Banner = () => {
    const hideBuyButton = isButtonBuyDisabled();

    return (
        <SectionBanner>
            <SectionContent spacing="none">
                <Row justifyContent="flex-start">
                    <Col number={ 2 } alignItems="flex-start">
                        <Headline align="left" type="attention" as="h1">
                            { translations.bannerHeadline01 }
                            <em>{ translations.bannerHeadline02 }</em>
                        </Headline>
                        <br/>
                        <Text size="medium">
                            { translations.bannerText01 }
                            <BannerBigText>{ ebookStats.EBOOK_TOTAL_EBOOKS }</BannerBigText>
                            <span
                                dangerouslySetInnerHTML={ { __html: translations.bannerText02 } }
                            />
                        </Text>
                        <Text size="medium">{ translations.bannerText03 }</Text>
                        <PricingWrapper>
                        <Row isMobileReverted>
                                <Col number={ 2 } alignItems="center">
                                    <FeatureBox
                                        img={ PiggyIcon }
                                        title={
                                            <>
                                                <PricingHeadline>{ translations.pricingHeadlineItem01 }</PricingHeadline>
                                                <PricingPrice>{ getBundlePrice }</PricingPrice>
                                            </>
                                        }
                                        text={
                                            <>
                                                <PricingContent/>
                                                <Button
                                                    as="a"
                                                    rel="nofollow"
                                                    dataId="banner-button-buy"
                                                    href={ !hideBuyButton ? routes.TRAVEL_PACK_URL_BUY : undefined }
                                                    isDisabled={ hideBuyButton }
                                                >
                                                    { translations.bannerButtonText }
                                                </Button>
                                            </>
                                        }
                                    />
                                </Col>
                                <Col number={ 2 } alignItems="center">
                                    <FeatureBox
                                        img={ BookIcon }
                                        title={
                                            <>
                                                <PricingHeadline>{ translations.pricingHeadlineItem02 }</PricingHeadline>
                                                <PricingPrice>{ getRegularPrice }</PricingPrice>
                                            </>
                                        }

                                        text={ <PricingContent/> }
                                    />
                                </Col>
                            </Row>
                        </PricingWrapper>
                    </Col>
                </Row>
            </SectionContent>
        </SectionBanner>
    );
};


export default Banner;
