import styled from 'styled-components';


const NavigationToggleButton = styled.button`
    position: fixed;
    top: 46px;
    right: ${ ( { theme } ) => theme.spacing07 };
    background: ${ ( { theme } ) => theme.ui01 };
    border-radius: 50%;
    height: 50px;
    width: 50px;
    outline: none;
    cursor: pointer;
    z-index: ${ ( { theme } ) => theme.floor04 };
    padding: ${ ( { theme } ) => theme.spacing08 };
    border: 4px solid ${ ( { theme } ) => `${theme.ui04}52` };

    svg {
        height: 100%;
        width: 100%;
        fill: ${ ( { theme } ) => theme.ui03 };
    }

    &:hover svg {
        fill: ${ ( { theme } ) => theme.ui07 };
    }
`;

export default NavigationToggleButton;
