export const TRAVEL_PACK_PRICE = 219.00;
export const EBOOK_TOTAL_EBOOKS = 63;

export const TRAVEL_PACK_SIZE = '2.71 GB';
export const EBOOK_TOTAL_EBOOKS_TYPE_STORY = 4;
export const EBOOK_TOTAL_PAGES_TYPE_FOOD = 1109;
export const EBOOK_TOTAL_PAGES_TYPE_AUDIOBOOK = 1;
export const EBOOK_TOTAL_DESTINATIONS = 37;
export const EBOOK_TOTAL_PAGES = 9098;
export const EBOOK_TOTAL_RECOMMENDATION = '+400';

export const EBOOK_TOTAL_VALUE = 4078;


export const EBOOK_SAVING = `${ Math.round( ( ( EBOOK_TOTAL_VALUE - TRAVEL_PACK_PRICE ) / EBOOK_TOTAL_VALUE ) * 100 ) }%`;

