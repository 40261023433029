import styled from 'styled-components';


export const SectionBanner = styled.section`
    position: relative;
    margin: 0 auto;
    padding: 0;
    background-image: url('/assets/banner_bg.jpg');
    background-repeat: no-repeat;
    z-index: 2;
    background-position: bottom right;
    background-size: contain;
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        background-size: 260%;
        padding-bottom: 400px;
    }
`;

export default SectionBanner;
