import React from 'react';

import { HeadlineText, HeadlineWrapper, SubHeadingText } from '../styled';

const Headline = ( { type = 'secondary', align = 'center', as ='h2', subHeading, children, colorVariant = 'green' } ) => {
    return (
        <HeadlineWrapper align={ align }>
            { subHeading && <SubHeadingText>{ subHeading }</SubHeadingText> }
            <HeadlineText as={as} colorVariant={colorVariant} type={ type }> { children }</HeadlineText>
        </HeadlineWrapper>
    );
};

export default Headline;
