import styled from 'styled-components';
import { getBackgroundColor } from '../utils/getBackgroundColor'

export const ContestPrizeItem = styled.a`
    position: relative;
    background: ${ ( { variant } ) => getBackgroundColor( { variant  } ) };
    border-radius: ${({ theme }) => theme.borderRadiusM };
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    align-items: center;
    text-decoration: none;
    outline: none;
    
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        margin-bottom: 100px;
    }
`;

export default ContestPrizeItem;
