import styled from 'styled-components';


export const ContestImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    &:has(img:only-child) {
        grid-template-columns: 1fr;
    }
    
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
`;

export default ContestImageGrid;
