import styled from 'styled-components';
import { getSize } from '../utils';


const TextWrapper = styled.p`
    line-height: ${ ( { theme } ) => theme.fontLinHeightRegular };
    font-size: ${ ( { size, theme } ) => getSize( { size, theme } ) };
    color: ${ ( { theme, color } ) => color || theme.text01 };
    text-align: ${ ( { align } ) => align };
    
    em {
        white-space: nowrap;
        font-style: normal;
    }
`;

export default TextWrapper;
