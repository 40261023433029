import React from 'react';

import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';
import { ASSETS } from '@/constants/paths';
import { translations as commonTranslations } from '@/constants';
import { CONTEST_SOCIAL_LINK } from '@/constants/globals';

import {
    ContestStepBannerWrapper,
    ContestStepCircle,
    ContestStepCircleHeadline,
    ContestStepContent,
    ContestStepWrapper
} from '../styled';
import translations from '../constants/translations';

const ContestSteps = () => {

    return (
        <Section variant="dark">
            <Row>
                <Col number={ 1 }>
                    <Headline subHeading={ translations.contestStepsSubHeadline }>
                        { translations.contestStepsHeadline }
                    </Headline>
                </Col>
            </Row>
            <Spacer/>
            <Row direction="row">
                <Col number={ 2 }>
                    <ContestStepWrapper>
                        <ContestStepCircle>
                            <ContestStepCircleHeadline
                                color="inherit">{ translations.contestStepText }</ContestStepCircleHeadline>
                            01
                        </ContestStepCircle>
                        <ContestStepContent>
                            <Headline type="primary" as="h3">
                                { translations.contestStep01Headline }
                            </Headline>
                            <Spacer size="super-small"/>
                            <div
                                dangerouslySetInnerHTML={ { __html: translations.contestStep01Text } }
                            />
                            <Spacer size="super-small"/>
                        </ContestStepContent>
                    </ContestStepWrapper>
                </Col>
                <Col number={ 2 }>
                    <ContestStepWrapper>
                        <ContestStepCircle>
                            <ContestStepCircleHeadline>{ translations.contestStepText }</ContestStepCircleHeadline>
                            02
                        </ContestStepCircle>
                        <ContestStepContent>
                            <Headline type="primary">
                                { translations.contestStep02Headline }
                            </Headline>
                            <Spacer size="super-small"/>
                            <div
                                dangerouslySetInnerHTML={ { __html: translations.contestStep02Text } }
                            />
                        </ContestStepContent>
                    </ContestStepWrapper>
                </Col>
            </Row>
            <Row direction="row">
                <Col number={ 2 }>
                    <ContestStepWrapper>
                        <ContestStepCircle>
                            <ContestStepCircleHeadline>{ translations.contestStepText }</ContestStepCircleHeadline>
                            03
                        </ContestStepCircle>
                        <ContestStepContent>
                            <Headline type="primary">
                                { translations.contestStep03Headline }
                            </Headline>
                            <Spacer size="super-small"/>
                            <div
                                dangerouslySetInnerHTML={ { __html: translations.contestStep03Text } }
                            />
                        </ContestStepContent>
                    </ContestStepWrapper>
                </Col>
                <Col number={ 2 }>
                    <ContestStepWrapper>
                        <ContestStepCircle>
                            <ContestStepCircleHeadline
                                color="inherit">{ translations.contestStepText }</ContestStepCircleHeadline>
                            04
                        </ContestStepCircle>
                        <ContestStepContent>
                            <Headline type="primary">
                                { translations.contestStep04Headline }
                            </Headline>
                            <Spacer size="super-small"/>
                            <div
                                dangerouslySetInnerHTML={ { __html: translations.contestStep04Text } }
                            />
                        </ContestStepContent>
                    </ContestStepWrapper>
                </Col>
            </Row>
            <Spacer size="small"/>
            <Row direction="row">
                <Col number={ 1 }>
                    <div
                        dangerouslySetInnerHTML={ { __html: translations.contestStepsTextMain01 } }
                    />
                </Col>
            </Row>
            <Row>
                <Col number={ 1 }>
                    <Spacer size="medium"/>
                    <Button as="a" variant="secondary" dataId="contest-button-submit" href={ CONTEST_SOCIAL_LINK }
                            target="_blank">
                        { commonTranslations.commonButtonContest }
                    </Button>
                    <Spacer size="small"/>
                </Col>

            </Row>
            <Spacer size="medium"/>
            <Row direction="row">
                <Col number={ 1 }>
                    <ContestStepBannerWrapper src={ `${ ASSETS }/contest_background.jpg` }
                                              alt={ translations.contestStepsHeadline }/>
                </Col>
            </Row>
        </Section>
    );
};


export default ContestSteps;
