import styled from 'styled-components';


export const ContestHeadlineImage = styled.img`
    width: 48px;
    margin: 0 ${ ( { theme } ) => theme.spacing06 };

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        width: 30px;
    }
`;

export default ContestHeadlineImage;
