import React from 'react';
import { Link } from 'react-router-dom';

import Section from '@/components/Section';
import Logotype from '@/components/Logotype';
import { Col, Row } from '@/components/Layout';
import Navigation from '@/components/Navigation';
import { SITE_NAME } from '@/constants/globals';

const Header = () => {

    return (
        <Section spacing="small" variant="transparent">
            <Row justifyContent="flex-start">
                <Col number={ 2 } alignItems="flex-start">
                    <Link to="/" title={SITE_NAME}><Logotype/></Link>
                </Col>
                <Col number={ 2 }>
                    <Navigation/>
                </Col>
            </Row>
        </Section>
    );
};

export default Header;
