import React from 'react';

import Text from '@/components/Text';
import Spacer from '@/components/Spacer';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';

import { paths } from '@/constants';
import { useWindowResize } from '@/hooks';
import { ASSETS } from '@/constants/paths';

import translations from '../constants/translations';
import { ContestColumn, ContestHeadlineImage, ContestPrizeWrapper } from '../styled';
import PrizeItem from './PrizeItem';


export const ContestPrize = ( { isDisplayFullDescriptionEnabled } ) => {
    const { isMobile } = useWindowResize();
    return (
        <Section id="nagdrody">
            <Row>
                <Col number={ 1 }>
                    <Headline type="attention" colorVariant="orange" subHeading={ translations.contestSubHeadline }>
                        { !isMobile &&
                            <ContestHeadlineImage src={ `${ ASSETS }/exclamation_mark_icon.png` }
                                                  role="presentation"/> }
                        { translations.contestHeadline }
                        { !isMobile &&
                            <ContestHeadlineImage src={ `${ ASSETS }/exclamation_mark_icon.png` }
                                                  role="presentation"/> }
                    </Headline>
                    <Spacer size="small"/>
                    <Text size="medium">
                    <span
                        dangerouslySetInnerHTML={ { __html: translations.contestTextMain01 } }
                    />
                    </Text>
                </Col>
            </Row>
            <ContestPrizeWrapper>
                <Row direction="row">
                    <ContestColumn number={ 4 }>
                        <PrizeItem
                            variant="blue"
                            prizeImages={ [ `${ paths.ASSETS }/contest_prize_01.jpg` ] }
                            prizeName={ translations.contestHeadlineItem01 }
                            prizeQuantity={ translations.contestHeadlineNumber01 }
                            sponsorName={ translations.contestPrizeNameItem01 }
                            sponsorAvatar={ `${ paths.ASSETS }/contest_avatar_ryanair.jpg` }
                            fullDescription={ translations.contestPrizeDetails01 }
                            isDisplayFullDescriptionEnabled={ isDisplayFullDescriptionEnabled }
                        />
                    </ContestColumn>
                    <ContestColumn number={ 4 }>
                        <PrizeItem
                            variant="orange"
                            prizeImages={ [
                                `${ paths.ASSETS }/contest_prize_02_01.jpg`,
                                `${ paths.ASSETS }/contest_prize_02_02.jpg`,
                                `${ paths.ASSETS }/contest_prize_02_03.jpg`,
                                `${ paths.ASSETS }/contest_prize_02_04.jpg`,
                            ] }
                            prizeName={ translations.contestHeadlineItem02 }
                            prizeQuantity={ translations.contestHeadlineNumber02 }
                            sponsorName={ translations.contestPrizeNameItem02 }
                            sponsorAvatar={ `${ paths.ASSETS }/contest_avatar_moderndomki.jpg` }
                            sponsorLink={ translations.contestPrizeLinkItem02 }
                            sponsorLinkWWW={ translations.contestPrizeLinkWWWItem02 }
                            fullDescription={ translations.contestPrizeDetails02 }
                            isDisplayFullDescriptionEnabled={ isDisplayFullDescriptionEnabled }
                        />
                    </ContestColumn>
                    <ContestColumn number={ 4 }>
                        <PrizeItem
                            variant="yellow"
                            prizeImages={ [
                                `${ paths.ASSETS }/contest_prize_04_01.jpg`,
                                `${ paths.ASSETS }/contest_prize_04_03.png`,
                                `${ paths.ASSETS }/contest_prize_04_04.png`,
                                `${ paths.ASSETS }/contest_prize_04_05.png`,
                            ] }
                            prizeName={ translations.contestHeadlineItem04 }
                            prizeQuantity={ translations.contestHeadlineNumber03 }
                            sponsorName={ translations.contestPrizeNameItem04 }
                            sponsorAvatar={ `${ paths.ASSETS }/contest_avatar_veoli_botanica.jpg` }
                            sponsorLink={ translations.contestPrizeLinkItem04 }
                            sponsorLinkWWW={ translations.contestPrizeLinkWWWItem04 }
                            fullDescription={ translations.contestPrizeDetails04 }
                            isDisplayFullDescriptionEnabled={ isDisplayFullDescriptionEnabled }
                        />
                    </ContestColumn>
                    <ContestColumn number={ 4 }>
                        <PrizeItem
                            variant="green"
                            prizeImages={ [
                                `${ paths.ASSETS }/contest_prize_03_01.jpg`,
                                `${ paths.ASSETS }/contest_prize_03_02.jpg`,
                                `${ paths.ASSETS }/contest_prize_03_03.jpg`,
                                `${ paths.ASSETS }/contest_prize_03_04.jpg`,
                            ] }
                            prizeName={ translations.contestHeadlineItem03 }
                            prizeQuantity={ translations.contestHeadlineNumber02 }
                            sponsorName={ translations.contestPrizeNameItem03 }
                            sponsorAvatar={ `${ paths.ASSETS }/contest_avatar_ohworld.store.jpg` }
                            sponsorLink={ translations.contestPrizeLinkItem03 }
                            sponsorLinkWWW={ translations.contestPrizeLinkWWWItem03 }
                            fullDescription={ translations.contestPrizeDetails03 }
                            isDisplayFullDescriptionEnabled={ isDisplayFullDescriptionEnabled }
                        />
                    </ContestColumn>
                </Row>
            </ContestPrizeWrapper>
        </Section>
    );
};


export default ContestPrize;
